//
// Custom checkbox
//

.custom-checkbox {
  padding-right: 2rem;
  padding-left: 0;

  .custom-control-label {
    &::before {
      right: -2rem;
      left: auto;
    }
    &::after {
      right: -2rem;
      left: auto;
    }
  }
  .custom-control-input ~ .custom-control-label {
    cursor: pointer;
    font-size: $font-size-sm;
  }

  .custom-control-input {
    right: 0;
    left: auto;

    &:checked {
      ~ .custom-control-label {
        &::before {
          border-color: $custom-control-indicator-checked-border-color;
        }
        &::after {
          background-image: $custom-checkbox-indicator-icon-checked;
        }
      }
    }

    &:disabled {
      ~ .custom-control-label {
        &::before {
          border-color: $custom-control-indicator-disabled-bg;
        }
      }

      &:checked {
        &::before {
          border-color: $custom-control-indicator-checked-disabled-bg;
        }
      }
    }
  }
}
