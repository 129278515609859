.print-page-layout {
  .container {
    border: 1px solid;
    margin-top: 50px;
    padding: 10px;
    background-color: white;

    .section {
      border: 1px solid;
      padding: 25px;

      h2 {
        font-size: 32px;
      }

      &:not(:last-child) {
        border-bottom: 0;
      }

      &-brand {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .brand-details {
          p {
            margin-bottom: 5px;
            font-weight: bold;
            font-size: 22px;
          }
        }
        .brand-logo {
          display: flex;
          align-items: center;

          img {
            height: 75px;
          }
        }
        .date {
          margin-top: 10px;
          margin-bottom: 0;
          font-weight: bold;
        }
      }

      &-package-details {
        .section-body {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: flex-start;

          .package-info {
            p {
              margin: 0;
              font-size: 24px;
            }
          }
          .collection {
            padding: 20px;
            background-color: lightgray;
            border: 1px dashed black;
            border-radius: 15px;
            display: flex;
            flex-direction: column;
            align-items: center;
            p {
              color: black;
              margin: 0;
              font-size: 24px;
              &.value {
                font-weight: bold;
              }
            }
          }
        }
      }

      &-address {
        .section-body {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: flex-start;

          .address-info,
          .customer-note {
            flex: 1;
            p {
              margin: 0;
              font-size: 24px;
            }
          }
        }
      }

      &-package-code {
        padding: 0;
        .section-body {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;

          .package-barcode,
          .package-qrcode {
            text-align: center;
            width: 100%;
            padding: 20px;
            p {
              margin-top: 10px;
              margin-bottom: 0;
              font-weight: bold;
              font: 20px monospace;
              color: black;
            }
          }
          .package-barcode {
            border-left: 1px solid;
          }
        }
      }

      &-terms {
        .section-body {
          p {
            margin: 0;
            font-size: 18px;
          }
        }
      }
    }
  }

  @page {
    size: A5;
  }
}
