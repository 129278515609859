//
// Nav
//

// Nav wrapper (container)

// Nav wrapper
.nav-wrapper {
  padding: 1rem 0;
  @include border-top-radius($card-border-radius);

  + .card {
    @include border-top-radius(0);
    @include border-bottom-radius($card-border-radius);
  }
}

// Nav links

.nav-link {
  color: $nav-link-color;

  &:hover {
    color: $nav-link-hover-color;
  }

  i.ni {
    position: relative;
    top: 2px;
    text-align: right;
  }
}

.notification-box {
  padding-right: 15px;
  .notification-result {
    min-width: max-content;

    .notification-text {
      margin: 0;
      color: gray;
      font-size: 12px;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 220px;
    }
    .notification-title {
      margin: 0;
      font-weight: bold;
      font-size: 14px;
    }
  }
}

.chat-box {
  .chat-result {
    min-width: max-content;
    padding-bottom: 0;
    padding-top: 0;
    .icon-shape {
      padding: 15px;
      width: 36px;
      height: 36px;
      i {
        font-size: 12px;
      }
    }
    img {
      width: 36px;
      border-radius: 50%;
    }
    .chat-text {
      margin: 0;
      color: gray;
      font-size: 12px;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 220px;
    }
    .chat-title {
      margin: 0;
      font-weight: bold;
      font-size: 14px;
    }
    .chat-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px 20px;
      min-width: 300px;
      &-title {
        font-weight: bold;
      }
      .chat-actions {
        display: flex;
      }
    }
  }
}
