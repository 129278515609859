.chat-list-page {
  padding-top: 88px;
  min-height: 100vh;
  display: flex;
  flex-direction: row;

  .chat-list-navigation {
    width: 25%;
    padding: 15px;
    //box-shadow: 0 0 2rem 0 rgba(136, 152, 170, 0.15);
    border-right: 1px solid lightgray;
    .chat-header-title {
      font-weight: bold;
      font-size: 24px;
    }
    .chat-actions {
      i {
        font-size: 24px;
      }
    }
    .search-box {
      input {
        width: 100%;
        border-radius: 20px;
        background-color: #f0f2f5;
        border: none;
        height: 40px;
        margin-top: 20px;
        padding: 20px;
      }
    }
    .chat-list {
      ul {
        list-style: none;
        padding: 0;
        margin-top: 20px;
        li {
          padding: 5px 10px;
          margin-bottom: 10px;
          border-radius: 12px;
          height: 70px;
          display: flex;
          align-items: center;

          .message-item {
            display: flex;
            flex-direction: row;
            img {
              width: 48px;
              border-radius: 50%;
              margin-left: 10px;
            }
            .icon-shape {
              margin-left: 10px;
            }
            .name {
              font-weight: bold;
              font-size: 16px;
              margin: 0;
              color: black;
            }
            .last-message {
              font-size: 12px;
              margin: 0;
              color: gray;
            }
          }

          &:hover,
          &.selected {
            background-color: #fe6f2f;
            .message-item {
              .name,
              .last-message {
                color: white;
              }
              .icon-shape {
                color: #fe6f2f !important;
                background-color: white !important;
              }

              .badge {
                background-color: white !important;
              }
            }
          }
        }
      }
    }
  }
  .chat-list-details {
    width: 75%;
    position: relative;

    .chat-details-header {
      img {
        width: 48px;
        border-radius: 50%;
        margin-left: 10px;
      }
      .name {
        font-weight: bold;
        font-size: 16px;
        color: black;
      }
      .mobile {
        font-size: 12px;
        color: gray;
      }
    }

    .chat-details-footer {
      position: absolute;
      bottom: 0;
      width: 100%;

      .form-group {
        width: 100%;

        input {
          border-bottom-right-radius: 15px;
          border-top-right-radius: 15px;
          height: 50px;
        }

        .input-group-text {
          border-bottom-left-radius: 15px;
          border-top-left-radius: 15px;
          cursor: pointer;
          i {
            font-size: 20px;
            color: #fe6f2f;
          }
        }
      }
    }

    .message-item {
      margin-bottom: 20px;

      &.received {
        float: left;
        clear: both;

        .message-text {
          background-color: #fe6f2f;
          color: white;
        }
      }

      .message-text {
        background-color: white;
        color: black;
        padding: 10px 20px;
        border-top-right-radius: 15px;
        border-bottom-left-radius: 15px;
      }
    }
  }
}
