//
// Modal
//

// Fluid modal

.modal-fluid {
  .modal-dialog {
    margin-top: 0;
    margin-bottom: 0;
  }
  .modal-content {
    border-radius: 0;
  }
}

// Background color variations

@each $color, $value in $theme-colors {
  .modal-#{$color} {
    @include modal-variant($value);
  }
}

.modal-dialog {
  .modal-header {
    .close {
      margin: -1rem auto -1rem -1rem;
    }
  }
}

.customer-serach-modal {
  .customer-list {
    .customer-box {
      cursor: pointer;
      &.selected,
      &:hover {
        background-color: aliceblue;
      }
    }
  }
}

.search-result-modal {
  display: none;
  min-width: 90%;
  max-width: min-content;
  min-height: 400px;
  position: absolute;
  top: 80px;

  &.open {
    display: block;
  }
}
