//
// Input group
//

.input-group {
  box-shadow: $input-box-shadow;
  border-radius: $input-border-radius;
  transition: $transition-base;

  .form-control {
    box-shadow: none;

    &:not(:first-child) {
      border-left: 0;
      padding-left: 0;
    }
    &:not(:last-child) {
      border-left: 0;
      padding-left: 0;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
    &:focus {
      box-shadow: none;
    }
  }
}

.input-group-text {
  transition: $input-transition;
}

// Alternative input groups related to .form-control-alternative

.input-group-alternative {
  box-shadow: $input-alternative-box-shadow;
  border: 0;
  transition: box-shadow 0.15s ease;

  .form-control,
  .input-group-text {
    border: 0;
    box-shadow: none;
  }
}

.focused {
  .input-group-alternative {
    box-shadow: $input-focus-alternative-box-shadow !important;
  }
}

// .focus class is applied dinamycally from theme.js

.focused {
  .input-group {
    box-shadow: $input-focus-box-shadow;
  }

  .input-group-text {
    color: $input-group-addon-focus-color;
    background-color: $input-group-addon-focus-bg;
    border-color: $input-group-addon-focus-border-color;
  }

  .form-control {
    border-color: $input-group-addon-focus-border-color;
  }
}
