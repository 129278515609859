//
// Tabs
//

.ant-tabs {
  .ant-tabs-tab:not(:first-child) {
    margin: 0 32px 0 0;
  }
  color: #133500;
}
.ant-tabs-tab-btn {
  color: #363636;
  text-shadow: 0 0 0.25px currentcolor;
}
// .ant-tabs-tab-btn :active{
//     color: #1fd900;
//     text-shadow: 0 0 0.25px currentcolor;
// }
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #fe6f2f !important;
}
.ant-tabs .ant-tabs-ink-bar {
  position: absolute;
  background: #fe6f2f;
  pointer-events: none;
}
