.floating-lable-input {
  position: relative;
  margin-bottom: 20px;

  &-lable {
    position: absolute;
    top: -12px;
    z-index: 10;
    background: white;
    right: 20px;
    padding: 0 10px;
  }
}
