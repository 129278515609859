//
// Profile card
//

.card-profile-image {
  text-align: center;
  .avatar {
    @extend .shadow;
    margin-top: 40px;
    left: 50%;
    transition: $transition-base;

    @include media-breakpoint-up(md) {
      transform: translate(-50%, -45%);
      position: absolute;
      margin-top: 0;
    }

    &:hover {
      transform: translate(-50%, -50%);
    }

    img {
      max-width: 180px;
    }
  }
}

.card-profile-stats {
  padding: 1rem 0;

  > div {
    text-align: center;
    margin-right: 1rem;
    padding: 0.875rem;

    &:last-child {
      margin-right: 0;
    }

    .heading {
      font-size: 1.1rem;
      font-weight: bold;
      display: block;
    }
    .description {
      font-size: 0.875rem;
      color: $gray-500;
    }
  }
}

.card-profile-actions {
  padding: 0.875rem;
}
